// frontend/src/components/formSteps/ProjectDetails.js

import React from 'react';
import { toast } from 'react-toastify';

/* eslint-disable no-unused-vars */
const ProjectDetails = ({ handleInputChange, values }) => {
  const handleNext = () => {
    const requiredFields = [
      'mainMarket',
      'languages'
    ];

    const missingFields = requiredFields.filter(field => !values[field]);

    if (missingFields.length > 0) {
      toast.error('Lütfen tüm gerekli alanları doldurun: ' + missingFields.join(', '));
      highlightMissingFields(missingFields);
      return;
    }
  };

  const highlightMissingFields = (fields) => {
    fields.forEach(field => {
      const element = document.getElementsByName(field)[0];
      if (element) {
        element.classList.add('highlight');
      }
    });
  };

  const handleCheckboxChange = (e) => {
    handleInputChange({
      target: {
        name: e.target.name,
        value: e.target.checked
      }
    });
  };

  return (
    <div className="project-details-container">
      <h2 className="project-details-title">Proje Detayları</h2>
      
      <div className="project-details-section">
        <label className="project-details-label" htmlFor="mainMarket">Ana Pazar: <span className="formrequiredsign">*</span></label>
        <span className="project-details-info">Satış yapacağınız ana pazarı satış yapmak istediğiniz ana para birimiyle birlikte yazınız. Örneğin: Türkiye - TL, İngiltere - GBP, Amerika Birleşik Devletleri - USD, Avrupa - EUR vs.</span>
        <input
          className="project-details-input"
          type="text"
          id="mainMarket"
          name="mainMarket"
          value={values.mainMarket}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="project-details-section">
        <label className="project-details-label" htmlFor="additionalMarket">Ek Pazarlar (Klon Siteler):</label>
        <span className="project-details-info">Satış yapmak istediğiniz ek pazarları para birimiyle birlikte yazınız. Örneğin: Global - USD, Avrupa - EUR vs.</span>
        <input
          className="project-details-input"
          type="text"
          id="additionalMarket"
          name="additionalMarket"
          value={values.additionalMarket}
          onChange={handleInputChange}
        />
      </div>

      <div className="project-details-section">
        <div className="project-details-info alert-info">
          <strong>Not:</strong> Shopify Payments kullanmayan firmalar her site için sadece tek bir para birimi ile ödeme alabilirler. 
          Shopify Payments Türkiye'deki firmalar için desteklenmemektedir. Bu nedenle, yurtdışı satış hedefi olan Türk markalar 
          bu pazarlara yönelik yeni bir Shopify mağazasına sahip olmalıdır. Bu mağazalar da Klon Site olarak adlandırılmaktadır.
        </div>
      </div>

      <div className="project-details-section">
        <label className="project-details-label" htmlFor="languages">Diller: <span className="formrequiredsign">*</span></label>
        <span className="project-details-info">Sitenin ana dilini seçiniz ve varsa ek dilleri ekleyiniz. Eğer ikinci bir klon site olacaksa bu site için de dilleri ayrıca belirtiniz.</span>
        <input
          className="project-details-input"
          type="text"
          id="languages"
          name="languages"
          value={values.languages}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="project-details-section">
        <label className="form-checkbox-container">
          <input
            type="checkbox"
            id="hasDigitalProducts"
            name="hasDigitalProducts"
            checked={values.hasDigitalProducts || false}
            onChange={handleCheckboxChange}
            className="form-checkbox-input"
          />
          <span className="form-checkbox-label">
          Manuel ürün ekleme işlemi yapılacaksa işaretleyiniz. (Ürünleriniz Excel formatında düzenli ve dijital ortamda hazır değilse veya ERP entegrasyonu ile içe aktarılmayacaksa ve manuel ürün ekleme işlemi yapılacaksa işaretleyiniz.)
          </span>
        </label>
      </div>

      {values.hasDigitalProducts && (
        <>
          <div className="project-details-section">
            <label className="project-details-label" htmlFor="productCount">Kaç ürününüz bulunmaktadır?</label>
            <input
              className="project-details-input"
              type="text"
              id="productCount"
              name="productCount"
              value={values.productCount}
              onChange={handleInputChange}
            />
          </div>

          <div className="project-details-section">
            <label className="project-details-label" htmlFor="categoryCount">Kaç kategoriniz bulunmaktadır?</label>
            <input
              className="project-details-input"
              type="text"
              id="categoryCount"
              name="categoryCount"
              value={values.categoryCount}
              onChange={handleInputChange}
            />
          </div>
        </>
      )}

      <div className="project-details-section">
        <label className="form-checkbox-container">
          <input
            type="checkbox"
            id="hasLiveSite"
            name="hasLiveSite"
            checked={values.hasLiveSite || false}
            onChange={handleCheckboxChange}
            className="form-checkbox-input"
          />
          <span className="form-checkbox-label">
            Talebe konu olan internet sitesi şu an yayında mı?
          </span>
        </label>
      </div>

      {values.hasLiveSite && (
        <div className="project-details-section">
          <label className="form-checkbox-container">
            <input
              type="checkbox"
              id="needOrderTransfer"
              name="needOrderTransfer"
              checked={values.needOrderTransfer || false}
              onChange={handleCheckboxChange}
              className="form-checkbox-input"
            />
            <span className="form-checkbox-label">
              Mevcut yapıdaki siparişler yeni siteye aktarılacak mı?
            </span>
          </label>
        </div>
      )}

      {values.needOrderTransfer && (
        <div className="project-details-section">
          <label className="project-details-label" htmlFor="orderCount">Aktarılması talep edilen sipariş sayısı nedir?</label>
          <input
            className="project-details-input"
            type="text"
            id="orderCount"
            name="orderCount"
            value={values.orderCount}
            onChange={handleInputChange}
          />
        </div>
      )}

      <div className="project-details-section">
        <label className="form-checkbox-container">
          <input
            type="checkbox"
            id="needTechnicalSupport"
            name="needTechnicalSupport"
            checked={values.needTechnicalSupport || false}
            onChange={handleCheckboxChange}
            className="form-checkbox-input"
          />
          <span className="form-checkbox-label">
            İnternet sitesi yazılımının tamamlanması sonrasında düzenli Teknik Destek ve Geliştirme hizmeti de almak ister misiniz?
          </span>
        </label>
      </div>
    </div>
  );
};
/* eslint-enable no-unused-vars */

export default ProjectDetails;
