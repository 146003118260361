import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getCustomerResponses } from '../api';
import './FormSummary.css';
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-toastify';
import { FaFile, FaFileImage, FaFileWord, FaFileExcel, FaFilePdf } from 'react-icons/fa';

const FormSummary = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { customerId } = useParams();
  const { user } = useAuth();

  const handleBackToAdmin = () => {
    navigate('/admin-dashboard');
  };

  useEffect(() => {
    // Kullanıcı kontrolü
    if (!user) {
      navigate('/login');
      return;
    }

    // Admin değilse ve kendi form özeti değilse erişimi engelle
    if (user.role !== 'admin' && user.customerId !== customerId) {
      toast.error('Bu sayfaya erişim yetkiniz yok');
      navigate('/customer-form');
      return;
    }

    const fetchFormData = async () => {
      try {
        const response = await getCustomerResponses(customerId);
        console.log('Form verileri:', response); // Gelen veriyi kontrol et
        setFormData(response.formData);
        setLoading(false);
      } catch (err) {
        console.error('Veri çekme hatası:', err);
        setError('Form verileri alınırken bir hata oluştu.');
        setLoading(false);
      }
    };

    fetchFormData();
  }, [customerId, user, navigate]);

  if (loading) return <div className="loading">Yükleniyor...</div>;
  if (error) return <div className="error">{error}</div>;
  if (!formData) return <div className="error">Form verisi bulunamadı.</div>;

  // Form alanlarını kategorilere ayır
  const sections = {
    'Kişisel Bilgiler': {
      name: 'İsim',
      email: 'E-posta',
      phoneNumber: 'Telefon',
      companyName: 'Şirket Adı',
      pocName: 'İletişim Kişisi Adı',
      pocEmail: 'İletişim Kişisi E-posta',
      pocPhone: 'İletişim Kişisi Telefon'
    },
    'Proje Detayları': {
      storeName: 'Mağaza Adı',
      storePhone: 'Mağaza Telefonu',
      storeEmail: 'Mağaza E-postası',
      billingAddress: 'Fatura Adresi',
      billingTaxInfo: 'Vergi Bilgileri',
      storeCurrency: 'Mağaza Para Birimi',
      shippingRates: 'Kargo Ücretleri',
      taxes: 'Vergiler',
      senderEmail: 'Gönderen E-posta',
      mainMarket: 'Ana Pazar',
      additionalMarket: 'Ek Pazarlar (Klon Siteler)',
      languages: 'Diller',
      hasDigitalProducts: 'Manuel ürün ekleme işlemi yapılacak mı? (Ürünleriniz Excel formatında düzenli ve dijital ortamda hazır değilse veya ERP entegrasyonu ile içe aktarılmayacaksa ve manuel ürün ekleme işlemi yapılacak mı?)',
      productCount: 'Ürün Sayısı',
      categoryCount: 'Kategori Sayısı',
      hasLiveSite: 'Talebe konu olan internet sitesi şu an yayında mı?',
      needOrderTransfer: 'Mevcut yapıdaki siparişler yeni siteye aktarılacak mı?',
      orderCount: 'Aktarılması talep edilen sipariş sayısı kaçtır?',
      needTechnicalSupport: 'İnternet sitesi canlıya çıktıktan sonra düzenli Teknik Destek ve Geliştirme hizmeti almak ister misiniz?',
      ...(formData.policies?.length > 0 ? { policies: 'Politika Dosyaları' } : {})
    },
    'Tasarım Detayları': {
      generalDesignBrief: 'Genel Tasarım Özeti',
      exampleDesignUrls: 'Örnek Tasarım URL\'leri',
      headerDesignBrief: 'Header Tasarım Açıklaması',
      headerDesignScreenshots: 'Header Tasarım Ekran Görüntüleri',
      footerDesignBrief: 'Footer Tasarım Açıklaması',
      footerDesignScreenshots: 'Footer Tasarım Ekran Görüntüleri',
      homepageDesignBrief: 'Anasayfa Tasarım Açıklaması',
      homepageDesignScreenshots: 'Anasayfa Tasarım Ekran Görüntüleri',
      collectionPageDesignBrief: 'Koleksiyon Sayfası Tasarım Açıklaması',
      collectionPageScreenshots: 'Koleksiyon Sayfası Ekran Görüntüleri',
      productPageDesignBrief: 'Ürün Sayfası Tasarım Açıklaması',
      productPageScreenshots: 'Ürün Sayfası Ekran Görüntüleri',
      otherPagesDesignBrief: 'Diğer Sayfalar Tasarım Açıklaması',
      otherPagesScreenshots: 'Diğer Sayfalar Ekran Görüntüleri'
    },
    'Entegrasyonlar': {
      marketplaceIntegrations: 'Pazar Yeri Entegrasyonları',
      erpIntegrations: 'ERP Entegrasyonları',
      paymentInfo: 'Ödeme Entegrasyonu',
      shippingInfo: 'Kargo Entegrasyonu',
      invoicingInfo: 'Fatura Entegrasyonu',
      selectedIntegrations: 'Kullanmak İstediğiniz Eklentiler'
    }
  };

  // File icon helper fonksiyonu ekle
  const getFileIcon = (filename) => {
    const extension = filename.split('.').pop().toLowerCase();

    switch(extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return <FaFileImage className="file-icon" />;
      case 'doc':
      case 'docx':
        return <FaFileWord className="file-icon" />;
      case 'xls':
      case 'xlsx':
      case 'csv':
        return <FaFileExcel className="file-icon" />;
      case 'pdf':
        return <FaFilePdf className="file-icon" />;
      default:
        return <FaFile className="file-icon" />;
    }
  };

  const renderValue = (key, value) => {
    // Boolean değerler için özel kontrol
    if (typeof value === 'boolean') {
      return value ? 'Evet' : 'Hayır';
    }

    // Tasarım brief alanları için özel kontrol
    if (key.includes('DesignBrief')) {
      return value || 'Özel bir tasarım talebi yapılmadı. Sitenin genel tasarım yapısına uygun olarak planlanacaktır.';
    }

    // Dosya ve ekran görüntüleri için özel işleme
    if (key.toLowerCase().includes('screenshots') || key === 'policies') {
      if (!value || value.length === 0) return 'Herhangi bir dosya yüklenmedi';
      return (
        <ul className="file-list">
          {value.map((file, index) => (
            <li key={index}>
              {file.originalName && (
                <a 
                  href={file.url}
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="file-link"
                >
                  <div className="file-info">
                    {getFileIcon(file.filename)}
                    <span className="file-name">{file.originalName}</span>
                  </div>
                </a>
              )}
            </li>
          ))}
        </ul>
      );
    }

    // Diğer değerler için varsayılan işleme
    if (!value || value.length === 0) return '-';
    return Array.isArray(value) ? value.join(', ') : value;
  };

  return (
    <div className="form-summary-container">
      {/* Admin için geri dönüş butonu */}
      {user?.role === 'admin' && (
        <button 
          className="admin-back-button"
          onClick={handleBackToAdmin}
        >
          ← Admin Panele Dön
        </button>
      )}

      <header className="summary-header">
        <img src="/assets/byte-logo.svg" alt="Byte Logo" className="logo" />
        <h1>Form Özeti</h1>
        <p className="customer-id">Müşteri ID: {customerId}</p>
      </header>

      <div className="summary-content">
        {Object.entries(sections).map(([sectionTitle, fields]) => (
          <section key={sectionTitle} className="summary-section">
            <h2>{sectionTitle}</h2>
            <div className="fields-grid">
              {Object.entries(fields).map(([key, label]) => {
                // Boolean değerler ve DesignBrief alanları için her zaman göster
                const shouldShow = key.includes('DesignBrief') || 
                                  typeof formData[key] === 'boolean' || 
                                  formData[key];
                                    
                return shouldShow && (
                  <div key={key} className="field-item">
                    <label>{label}:</label>
                    <div className="field-value">
                      {renderValue(key, formData[key])}
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        ))}
      </div>
    </div>
  );
};

export default FormSummary;